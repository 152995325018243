body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  outline: none;
}

input:focus {
  outline: #ffffff auto 1px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(40, 40, 40, 1);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

:root {
  --primary-grey: #898989;
  --primary-dark-grey: #636363;
  --primary-darker-grey: #282828;
  --primary-red: #541621;
  --primary-yellow: #f3b64e;

  --success-icon: #88ff88;
  --failed-icon: #ec5555;

  --container-padding: 60px 80px;
  /* small screen padding */
  --small-container-padding: 60px 40px;
  /* big screen padding */
  --big-container-padding: 80px 120px;

  --lora-font: "Lora", serif;
  --lora-bold: "LoraBold", serif;
  --lora-bold-italic: "LoraBoldItalic", serif;
  --lora-italic: "LoraItalic", serif;
  --sf-pro-italic: "SFProItalic", serif;
  --sf-pro: "SFPro", serif;

  --header-bg: #ffffff08;

  /* not in use */
  --section-title-text: 2rem; /*35.2px*/
  --title-text: 1.8rem; /*28.8px*/
  --content-text: 1.3rem; /*28.8px*/

  --large-text: 1.2rem; /* 24px */
  --normal-text: 1rem; /* 16px */
  --medium-text: 0.875rem; /* 14px */
  --small-text: 0.75rem; /* 12px */
  --smaller-text: 0.7rem; /* 11.2px */
}

@font-face {
  font-family: "LoraBold";
  src: local("LoraBold"), url("./fonts/Lora-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "LoraBoldItalic";
  src: local("LoraBoldItalic"),
    url("./fonts/Lora-BoldItalic.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "LoraItalic";
  src: local("LoraItalic"), url("./fonts/Lora-Italic.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Lora";
  src: local("Lora"), url("./fonts/Lora-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "SFProItalic";
  src: local("SFProItalic"), url("./fonts/SF-Pro-Italic.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "SFPro";
  src: local("SFPro"), url("./fonts/SF-Pro.ttf") format("truetype");
  font-weight: 400;
}
